/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { Text, Box, Flex, jsx, Button, Image } from 'theme-ui';
import Facebook from '../components/icons/facebook';
import Instagram from '../components/icons/Instagram';
import theme from '../gatsby-plugin-theme-ui/index';

import logo from '../assets/logo-transparent.png';
import NTG from '../assets/NTG.png';
import getGoogleStr from '../helpers/getGoogleStr';

const Footer = () => {
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [isHomePage, setIsHomePage] = useState(false);
  const [render, setRender] = useState(false);
  const line = {
    content: '',
    borderTop: '1px solid #F4BC33',
    width: '50px',
    display: 'inline-block',
    flex: '1 1 auto',
    marginTop: 2,
  };

  const routes = [
    {
      title: 'Home',
      route: '/',
    },
    {
      title: 'Places',
      route: '/places',
    },
    {
      title: 'AGW SONO Partners',
      route: 'https://agwpartners.com/',
    },
    {
      title: 'Spaces',
      route: 'https://agwpartners.com/availabilities/',
    },
    // {
    //   title: 'News',
    //   route: '/',
    // },
  ];

  const linkStyles = {
    color: 'white',
    fontSize: [2],
    textTransform: 'uppercase',
    letterSpacing: '2px',
    fontWeight: 600,
    '&:hover': {
      color: 'primary',
      transition: 'all .25s',
    },
  };

  const footerBoxStyle = {
    flex: ['1 1 100%', '1 1 33%', '1 1 25%'],
    paddingX: [2, 3, 4],
    textAlign: 'left',

    '> div': {
      marginBottom: 3,
      paddingTop: [3, 'unset'],
    },
  };

  useEffect(() => {
    if (window.location.pathname === '/') {
      setIsHomePage(true);
    }
  }, []);

  return (
    <>
      <Box sx={{ bg: 'white' }}>
        {isHomePage ? (
          // <Flex sx={{ justifyContent: 'center', width: '100%' }}>
          //   <Box
          //     sx={{
          //       textAlign: 'center',
          //       py: [3, 4, 5],
          //       fontWeight: 'bold',
          //       fontSize: [4, 5],
          //       textTransform: 'uppercase',
          //     }}>
          //     <Text as='h5' sx={{ mb: [2, 3, 4] }}>
          //       Sign up for Newsletter
          //     </Text>
          //     <div
          //       class='ctct-inline-form'
          //       data-form-id='4594864a-e725-4d96-8eb1-277d0fa0a5dc'></div>
          //   </Box>
          // </Flex>
          <></>
        ) : (
          ''
        )}
      </Box>
      <Box
        as="footer"
        sx={{
          paddingX: [3, 4],
          paddingY: [4],
          paddingTop: [4, 5],
          bg: 'text',
        }}
      >
        <Flex
          sx={{
            flexWrap: 'wrap',
            alignItems: 'center',
            // borderBottom: '1px solid #fff',
            paddingBottom: [2],
          }}
        >
          <Box
            sx={{
              flex: ['1 1 100%', '1 1 33%', '1 1 25%'],
              paddingX: [2, 3, 4],
            }}
          >
            <Image
              src={logo}
              alt="OnWashington"
              sx={{ width: '100%', maxWidth: '275px', mb: [3, 'unset'] }}
            />
          </Box>
          <Box sx={footerBoxStyle}>
            <Box>
              <Link sx={linkStyles} to={routes[0].route}>
                {routes[0].title}
              </Link>
            </Box>
            <Box>
              <Link sx={linkStyles} to={routes[1].route}>
                {routes[1].title}
              </Link>
            </Box>
          </Box>
          <Box sx={footerBoxStyle}>
            <Box>
              <Link sx={linkStyles} to={routes[2].route}>
                {routes[2].title}
              </Link>
            </Box>
            <Box>
              <Link sx={linkStyles} to={routes[3].route}>
                {routes[3].title}
              </Link>
            </Box>
            {/* <Box>
              <Link sx={linkStyles} to={routes[5].route}>
                {routes[5].title}
              </Link>
            </Box> */}
          </Box>
          <Box sx={footerBoxStyle}>
            <Box
              sx={{
                textAlign: 'center',
                py: [3],
                fontWeight: 'bold',
                fontSize: [4, 5],
                textTransform: 'uppercase',
              }}
            >
              {/* <Text as="h5" sx={{ mb: [0], color: 'white' }}>
                Sign up for Newsletter
              </Text> */}
              <a
                href="https://visitor.r20.constantcontact.com/manage/optin?v=001z-eS0F3Bcfb_bwW2AX3Wa1ioj2Xh1ikB5NXAJ7nGgf5rCHHqKrAK6xcNVrUcg8MEVnWXF6IbODD32pZgy8sGic-81EffqaC8lT8xFg_C8rw3UeW3BzWi4pRefmWsCexzPSCxGtuWFFk8mXo9veyNuQ%3D%3D"
                target="_blank"
              >
                <Button
                  sx={{
                    bg: 'primary',
                    color: 'white',
                    textTransform: 'uppercase',
                    letterSpacing: '2px',
                    fontWeight: 600,
                    fontSize: [4],
                    border: '1px solid',
                    borderColor: 'primary',
                    cursor: 'pointer',
                    '&:hover': { bg: 'transparent', transition: 'all .25s' },
                  }}
                >
                  SIGN UP FOR OUR NEWSLETTER
                </Button>
              </a>
              {/* <div
                class="ctct-inline-form"
                data-form-id="4594864a-e725-4d96-8eb1-277d0fa0a5dc"
              ></div> */}
            </Box>
          </Box>
          {/* <Box sx={footerBoxStyle}></Box> */}
        </Flex>
        <Box sx={{ paddingY: [3] }}>
          <Flex
            variant="footerDetails"
            sx={{
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ paddingX: 2 }}>
              <a
                href="https://www.instagram.com/onwashingtonst/"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={() => setHoveredIcon('instagram')}
                onMouseLeave={() => setHoveredIcon(null)}
              >
                <Instagram
                  width="25px"
                  fill={
                    hoveredIcon === 'instagram' ? theme.colors.primary : '#fff'
                  }
                ></Instagram>
              </a>
            </Box>
            <Box sx={{ paddingX: 2 }}>
              <a
                href="https://www.facebook.com/onwashingtonst/"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={() => setHoveredIcon('facebook')}
                onMouseLeave={() => setHoveredIcon(null)}
              >
                <Facebook
                  width="25px"
                  fill={
                    hoveredIcon === 'facebook' ? theme.colors.primary : '#fff'
                  }
                ></Facebook>
              </a>
            </Box>
          </Flex>
        </Box>
        <Box>
          <Flex sx={{ justifyContent: 'center', pt: 3 }}>
            <Text
              as="p"
              variant="footerDetails"
              sx={{
                textAlign: 'center',
                a: {
                  color: 'white',
                  textDecoration: 'none',
                  color: 'white',
                  fontSize: [2, '16px'],
                  textTransform: 'uppercase',
                  letterSpacing: '2px',
                  fontWeight: 400,
                  '&:hover': {
                    color: 'primary',
                    transition: 'all .25s',
                  },
                },
              }}
            >
              <a
                target="_blank"
                href={getGoogleStr(
                  '',
                  '135 Washington Street',
                  'Norwalk',
                  '06854',
                  'CT'
                )}
              >
                Washington Street, South Norwalk, CT 06854
              </a>
              <Text
                as="p"
                sx={{
                  mt: 4,
                  lineHeight: 1.75,
                  color: 'white',
                  fontSize: [2, '16px'],
                  textTransform: 'uppercase',
                  letterSpacing: '2px',
                  fontWeight: 400,
                  '&:hover': {
                    color: 'primary',
                    transition: 'all .25s',
                  },
                }}
              >
                PR/Marketing Inquiries: Email{' '}
                <a
                  href="mailto: linda@maxexposure.net"
                  style={{
                    textDecoration: 'underline',
                    color: 'white',
                    fontSize: [2, 3],
                    textTransform: 'uppercase',
                    letterSpacing: '2px',
                    fontWeight: 600,
                    '&:hover': {
                      color: 'primary',
                      transition: 'all .25s',
                    },
                  }}
                >
                  Here
                </a>{' '}
                <br /> or call 203-561-9878
              </Text>
            </Text>
          </Flex>
        </Box>
        <Text sx={{ textAlign: 'center', mt: 2 }}>
          <Image
            src={
              'https://www.gonationsites.com/GNSE/gn-sites/images/gn-power-white.svg'
            }
            alt="Local Powered By GoNation"
            sx={{ maxWidth: '200px' }}
          ></Image>
        </Text>
      </Box>
    </>
  );
};

export default Footer;
